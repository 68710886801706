/* eslint-disable max-len */
import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	GET_USERS,
	GET_PROFILE_DATA,
	REGISTER_USER,
	setProfileData,
	startLoadingUsers,
	stopLoadingUsers,
	startLoginLoading,
	stopLoginLoading,
	setLoggedUser,
	GET_LOGGED_USER,
	DELETE_USER,
	CHANGE_USER_PASSWORD,
	setUsers,
} from '../actions/users.actions';
import { logout, setAuthForm } from '../actions/auth.actions';
import { navigate } from 'gatsby';

const getUsersMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_USERS) {
		dispatch(startLoadingUsers());

		try {
			const response = await httpProvider.get(`users?period=${action.payload}`);

			if (response.data) {
				const transformedUsers = response.data.map((user, index) => ({
					...user,
					position: index + 1,
				}));
				dispatch(setUsers(transformedUsers));
			}
		} catch (err) {
			dispatch(setUsers([]));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingUsers());
		}
	} else {
		return next(action);
	}
};

const registerUserMiddleware =
  ({ dispatch }) =>
  	next =>
  		async action => {
  			if (action.type === REGISTER_USER) {
  				dispatch(startLoadingUsers());

  				try {
  					const response = await httpProvider.post('users', action.payload);

  					if (response.data) {
  						toast.success('Успешна регистрация!', {
  							autoClose: false,
  						});
  						toast.info(`Изпратихме Ви имейл с линк за верификация. Моля, проверете имейла си (${action.payload.email}) и потвърдете вашият профил.`, {
  							autoClose: false,
  						});
  					}

  					dispatch(setAuthForm(null));
  				} catch (err) {
  					if (err.code === 500) {
  						toast.error('Нещо се обърка! Моля опитайте по-късно.');
  					} else {
  						toast.error(err.response.data.message);
  					}
  				} finally { 
  					dispatch(stopLoadingUsers());
  				}
  			} else {
  				return next(action);
  			}
  		};

const getLoggedUserMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_LOGGED_USER) {
		dispatch(startLoginLoading());
			
		try {
			const response  = await httpProvider.get(
				'auth',
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);
				
			if (response.data) {
				dispatch(setLoggedUser(response.data));
			}
		} catch (err) {
			// TODO - wtf to do here
		} finally {
			dispatch(stopLoginLoading());
		}
	} else {
		return next(action);
	}
};

const deleteUserMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === DELETE_USER) {
		dispatch(startLoadingUsers());
			
		try {
			const response  = await httpProvider.remove(`users/${action.payload.userId}`, {}, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
				
			if (response.status === 200) {

				toast.success('Успешно изтрихте своя профил!');
				navigate('/');
				dispatch(logout());
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingUsers());
		}
	} else {
		return next(action);
	}
};

const changeUserPasswordMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === CHANGE_USER_PASSWORD) {
		dispatch(startLoadingUsers());

		try {
			const response  = await httpProvider.post(
				`users/${action.payload.userId}/passwords`,
				action.payload.passwords,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);
				
			if (response.data) {
				dispatch(logout());
				toast.success('Успешно променихте паролата си!');
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error(err.response.data.message);
			}
		} finally {
			dispatch(stopLoadingUsers());
		}
	} else {
		return next(action);
	}
};

const getProfileDataMiddleware =
  ({ dispatch }) =>
  	next =>
  		async action => {
  			if (action.type === GET_PROFILE_DATA) {
  				dispatch(startLoadingUsers());

  				try {
  					const response = 
							await httpProvider.get(`users/${action.payload.username}?date=${new Date()}`);

  					if (response.data) {
  						dispatch(setProfileData(response.data));
  					}
  				} catch (err) {
  					if (err.code === 500) {
  						toast.error('Нещо се обърка! Моля опитайте по-късно.');
  					} else {
  						toast.error('Такъв профил не съществува!');
  					}
  				} finally {
  					dispatch(stopLoadingUsers());
  				}
  			} else {
  				return next(action);
  			}
  		};

export {
	getUsersMiddleware,
	registerUserMiddleware,
	getProfileDataMiddleware,
	getLoggedUserMiddleware,
	deleteUserMiddleware,
	changeUserPasswordMiddleware
};
