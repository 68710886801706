import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './src/state/Store';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { getLoggedUser } from './src/state/actions/users.actions';

const ClientOnlyToastContainer = () => {
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	return isClient ? (
		<ToastContainer
			pauseOnFocusLoss={false}
			position="top-left" theme="colored"
			closeOnClick style={{ zIndex: 2000000 }}
		/>
	) : null;
};

export const wrapRootElement = ({ element }) => {
	return (
		<Provider store={store}>
			<ClientOnlyToastContainer />
			{element}
		</Provider>
	);
};

export const onInitialClientRender = () => {
	if (typeof window !== 'undefined') {
		store.dispatch(getLoggedUser());
	}
};

// Add this function to dismiss toasts on route change
export const onRouteUpdate = () => {
	toast.dismiss();
};