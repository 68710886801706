const GET_USERS = 'GET_USERS';
const SET_USERS = 'SET_USERS';
const GET_LOGGED_USER = 'GET_LOGGED_USER';
const SET_LOGGED_USER = 'SET_LOGGED_USER';
const SET_LOGGED_USER_DAILY_VOTES = 'SET_LOGGED_USER_DAILY_VOTES';
const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
const DELETE_USER = 'DELETE_USER';
const REGISTER_USER = 'REGISTER_USER';
const START_LOADING_USERS = 'START_LOADING_USERS';
const STOP_LOADING_USERS = 'STOP_LOADING_USERS';
const START_LOGIN_LOADING = 'START_LOGIN_LOADING';
const STOP_LOGIN_LOADING = 'STOP_LOGIN_LOADING';

const getUsers = (payload) => ({
	type: GET_USERS,
	payload
});

const getLoggedUser = () => ({
	type: GET_LOGGED_USER
});

const deleteUser = (payload) => ({
	type: DELETE_USER,
	payload
});

const changeUserPassword = (payload) => ({
	type: CHANGE_USER_PASSWORD,
	payload
});

const setLoggedUser = (payload) => ({
	type: SET_LOGGED_USER,
	payload
});

const setLoggedUserDailyVotes = (payload) => ({
	type: SET_LOGGED_USER_DAILY_VOTES,
	payload
});

const getProfileData = (userId) => ({
	type: GET_PROFILE_DATA,
	payload: userId
});

const setProfileData = (payload) => ({
	type: SET_PROFILE_DATA,
	payload
});

const setUsers = (payload) => ({
	type: SET_USERS,
	payload
});

const registerUser = (payload) => ({
	type: REGISTER_USER,
	payload
});

const startLoadingUsers= () => ({
	type: START_LOADING_USERS
});

const stopLoadingUsers = () => ({
	type: STOP_LOADING_USERS
});

const startLoginLoading = () => ({
	type: START_LOGIN_LOADING
});

const stopLoginLoading = () => ({
	type: STOP_LOGIN_LOADING
});

export {
	GET_USERS,
	REGISTER_USER,
	START_LOADING_USERS,
	STOP_LOADING_USERS,
	START_LOGIN_LOADING,
	STOP_LOGIN_LOADING,
	GET_PROFILE_DATA,
	SET_PROFILE_DATA,
	GET_LOGGED_USER,
	SET_LOGGED_USER,
	DELETE_USER,
	CHANGE_USER_PASSWORD,
	SET_USERS,
	SET_LOGGED_USER_DAILY_VOTES,
	changeUserPassword,
	getUsers,
	setUsers,
	registerUser,
	startLoadingUsers,
	stopLoadingUsers,
	startLoginLoading,
	stopLoginLoading,
	getProfileData,
	setProfileData,
	getLoggedUser,
	setLoggedUser,
	deleteUser,
	setLoggedUserDailyVotes
};