import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	LOGIN,
	LOGOUT,
	setAuthForm,
	startLoadingAuth,
	stopLoadingAuth
} from '../actions/auth.actions';
import { getLoggedUser, setLoggedUser } from '../actions/users.actions';
import { navigate } from 'gatsby';
import { resetFixtureDetails } from '../actions/fixture-details.actions';

const loginMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === LOGIN) {
		dispatch(startLoadingAuth());

		try {
			const response  = await httpProvider.post('auth/login', action.payload);
  
			if (response.data) {
				localStorage.setItem('token', response.data.token);
				dispatch(getLoggedUser());
				dispatch(setAuthForm(null));
				toast.success('Успешно влизане!');
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error(err.response.data.message);
			}
		} finally {
			dispatch(stopLoadingAuth());
		}
	} else {
		return next(action);
	}
};

const logoutMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === LOGOUT) {
		dispatch(startLoadingAuth());

		try {
			localStorage.removeItem('token');
			dispatch(setLoggedUser(null));
			dispatch(resetFixtureDetails);
			navigate('/');
			toast.success('Успешно излизане!');

		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error(err.response.data.message);
			}
		} finally {
			dispatch(stopLoadingAuth());
		}
	} else {
		return next(action);
	}
};

export {
	loginMiddleware,
	logoutMiddleware
};